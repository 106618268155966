<template>
  <div class="external-module-parameters">
    <PageTitle
      title="第三方交易模組設定"
      icon="chevron_left"
      hideBtn
      style="margin-bottom: 64px"
      @iconClick="$router.push({ name: 'Parameters' })"
    />

    <div class="flex flex-col" style="gap: 24px">
      <MemberPointConfigBlock
        v-if="checkAction('admin.shopPoint.page')"
        v-loading="loading"
        title="第三方交易點數設定"
        :configData="configData"
        :hideOptions="['IMMEDIATELY']"
        source="MEMBER_EXTERNAL_TRANSACTION"
        rewardType="POINT"
        hideShopPointEdit
        @refresh="refresh"
      />

      <!-- <OpentixSalesConfirmTimeSettingsBlock v-if="checkAction('admin.couponOpentix.page')" /> -->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted } from 'vue'
import PageTitle from '@/components/Title/PageTitle'
import MemberPointConfigBlock from '@/views/Parameters/components/MemberPointConfigBlock.vue'
// import OpentixSalesConfirmTimeSettingsBlock from './components/OpentixSalesConfirmTimeSettingsBlock.vue'
import store from '@/store'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'ExternalModuleParameters',
  components: {
    PageTitle,
    MemberPointConfigBlock,
    //  OpentixSalesConfirmTimeSettingsBlock
  },
  setup (props) {
    const { checkAction } = usePermissions()
    const shopId = computed(() => store.getters.shop)
    const loading = ref(false)
    const configData = ref({})

    const getPaymentConfig = async () => {
      // const [res, err] = await GetMemberShopInvoiceConfig({ shopId: shopId.value })
      // if (err) window.$message.error(err)
      // configData.value = res
    }

    const refresh = async () => {
      if (loading.value) return
      loading.value = true
      await getPaymentConfig()
      loading.value = false
    }

    onMounted(async () => {
      await refresh()
    })

    return { loading, refresh, configData, checkAction }
  },
})
</script>

<style scoped lang="postcss">
.parameters-category-list {
  @apply grid grid-cols-3 gap-[44px];
}

@media screen and (max-width: 1200px) {
  .parameters-category-list {
    @apply grid grid-cols-2;
  }
}
</style>
